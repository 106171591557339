import React from 'react';
import '../assets/CalculationAssist.css'; // Passe den Pfad an deine CSS-Datei an
import Header from '../assets/Header.js';  // Passe den Pfad zu deiner Header-Komponente an

function CalculationAssist() {
  return (
    <div className="calculation-assist-container">
      <Header title="Your LV Assistant" />

      {/* Hero Section */}
      <div className="hero-section">
        <h1>Welcome to your LV Assistant</h1>
        <p className="hero-subtitle">
          Accelerate your design process with intelligent insights and guidance, all integrated seamlessly into Design Atlas 2025.
        </p>
      </div>

      {/* Overview Cards */}
      <div className="features-section">
        <h2>What can the LV Assistant do for you?</h2>
        <div className="features-grid">
         
          <div className="feature-card">
            <h3>Input Data Assistance</h3>
            <p>
              Provide clarity on the necessary input parameters. The toolbox offers suggestions and best practices for entering your data correctly.
            </p>
          </div>

          <div className="feature-card">
            <h3>Optimize Your Calculations</h3>
            <p>
              Let the AI review your calculations for potential optimization. Improve efficiency and accuracy with minimal effort.
            </p>
          </div>

          <div className="feature-card">
            <h3>Validate Results</h3>
            <p>
              The toolbox can check your calculation results for consistency and plausibility, helping you ensure reliable outcomes.
            </p>
          </div>

          <div className="feature-card">
            <h3>Guidance and Explanations</h3>
            <p>
              Get detailed explanations for input parameters and calculation steps. Gain deeper insights into the reasoning behind each step.
            </p>
          </div>

          <div className="feature-card">
            <h3>Copilot Functionality</h3>
            <p>
              The integrated AI Copilot can provide you with calculation data and relevant details on-the-fly, streamlining your workflow.
            </p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="cta-section">
        <h2>Ready to Get Started?</h2>
        <p>
          Dive in and explore the AI Toolbox features, or select a module from the navigation above to begin your calculation journey.
        </p>
        <button className="btn btn-primary">Explore more Modules</button>
      </div>
    </div>
  );
}

export default CalculationAssist;
